import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/imgs/appstore_en.webp';
import _imports_1 from '@/assets/imgs/google_en.webp';
const _hoisted_1 = {
  class: "download"
};
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/_defineComponent({
  __name: 'Download',
  setup(__props) {
    const {
      t
    } = useI18n();
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("div", null, [_createElementVNode("a", {
        href: "https://apps.apple.com/app/mornin-key/id6448167378",
        target: "_blank"
      }, [_createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })])], -1), _createElementVNode("div", null, [_createElementVNode("a", {
        href: "https://play.google.com/store/apps/details?id=one.mornin.key",
        target: "_blank"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })])], -1)]));
    };
  }
});