import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/imgs/home/top.png';
import _imports_1 from '@/assets/imgs/home/key.png';
import _imports_2 from '@/assets/imgs/home/zone.png';
import _imports_3 from '@/assets/imgs/down.png';
import _imports_4 from '@/assets/imgs/up.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "wrap wrap-top"
};
const _hoisted_3 = {
  class: "top"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "wrap wrap-key"
};
const _hoisted_6 = {
  class: "key"
};
const _hoisted_7 = {
  class: "right"
};
const _hoisted_8 = {
  class: "zone"
};
const _hoisted_9 = {
  class: "left"
};
const _hoisted_10 = {
  class: "qa"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = {
  key: 0,
  src: _imports_3,
  alt: ""
};
const _hoisted_13 = {
  key: 1,
  src: _imports_4,
  alt: ""
};
const _hoisted_14 = {
  class: "soon"
};
const _hoisted_15 = {
  class: "input"
};
const _hoisted_16 = ["placeholder"];
const _hoisted_17 = ["placeholder"];
const _hoisted_18 = {
  href: "#"
};
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {
    const {
      t
    } = useI18n();
    const qa = ref([]);
    const clickShow = (isShow, i) => {
      qa.value[i] = !isShow;
    };
    onMounted(() => {
      document.documentElement.scrollTop = 0;
      qa.value = new Array(10).fill(false);
    });
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('home.top.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('home.top.desc')), 1), _createVNode(_component_router_link, {
        to: "/contact"
      }, {
        default: _withCtx(() => [_createElementVNode("button", null, _toDisplayString(_unref(t)('zone.plan.btn')), 1)]),
        _: 1
      })]), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "right"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_0
      })], -1))])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_1
      })], -1)), _createElementVNode("div", _hoisted_7, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('home.key.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('home.key.desc')), 1), _createVNode(_component_router_link, {
        to: "/key"
      }, {
        default: _withCtx(() => [_createElementVNode("button", null, _toDisplayString(_unref(t)('btn.more')), 1)]),
        _: 1
      })])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('home.zone.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('home.zone.desc')), 1), _createVNode(_component_router_link, {
        to: "/zone"
      }, {
        default: _withCtx(() => [_createElementVNode("button", null, _toDisplayString(_unref(t)('btn.more')), 1)]),
        _: 1
      })]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "right"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_2
      })], -1))])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('home.qa.title')), 1), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(qa.value, (isShow, i) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(isShow && 'show')
        }, [_createElementVNode("div", {
          class: "q",
          onClick: $event => clickShow(isShow, i)
        }, [_createElementVNode("h4", null, _toDisplayString(_unref(t)(`home.qa.list[${i}].q`)), 1), !isShow ? (_openBlock(), _createElementBlock("img", _hoisted_12)) : (_openBlock(), _createElementBlock("img", _hoisted_13))], 8, _hoisted_11), _createElementVNode("p", null, _toDisplayString(_unref(t)(`home.qa.list[${i}].a`)), 1)], 2);
      }), 256))])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('home.soon.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('home.soon.desc')), 1), _createElementVNode("div", _hoisted_15, [_createElementVNode("input", {
        type: "text",
        placeholder: _unref(t)('home.soon.name')
      }, null, 8, _hoisted_16), _createElementVNode("input", {
        type: "text",
        placeholder: _unref(t)('home.soon.address')
      }, null, 8, _hoisted_17)]), _createElementVNode("a", _hoisted_18, [_createElementVNode("button", null, _toDisplayString(_unref(t)('home.soon.btn')), 1)])])]);
    };
  }
});