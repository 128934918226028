import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
import _imports_0 from '@/assets/imgs/key/main.png';
import _imports_1 from '@/assets/imgs/key/qa.png';
import _imports_2 from '@/assets/imgs/key/down.png';
import _imports_3 from '@/assets/imgs/key/right.png';
import _imports_4 from '@/assets/imgs/key/use.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "wrap wrap-top"
};
const _hoisted_3 = {
  class: "top"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "wrap wrap-why"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "qa"
};
const _hoisted_8 = {
  class: "main"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "icon"
};
const _hoisted_11 = {
  key: 0,
  class: "down",
  src: _imports_2,
  alt: ""
};
const _hoisted_12 = {
  key: 1,
  class: "right",
  src: _imports_3,
  alt: ""
};
const _hoisted_13 = {
  key: 0
};
const _hoisted_14 = {
  class: "wrap use"
};
const _hoisted_15 = {
  class: "main"
};
const _hoisted_16 = {
  class: "left"
};
import { onMounted, ref } from 'vue';
import Download from '@/components/Download.vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/_defineComponent({
  __name: 'Key',
  setup(__props) {
    const {
      t
    } = useI18n();
    const qa = ref([]);
    const clickShow = (isShow, i) => {
      qa.value[i] = !isShow;
    };
    onMounted(() => {
      document.documentElement.scrollTop = 0;
      qa.value = new Array(5).fill(false);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_0
      })], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('key.top.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('key.top.desc')), 1), _createVNode(Download)])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('key.feature.title')), 1), _createElementVNode("ul", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, i => {
        return _createElementVNode("li", null, [_createElementVNode("img", {
          src: require(`@/assets/imgs/key/${i - 1}.png`),
          alt: ""
        }, null, 8, _hoisted_6), _createElementVNode("h3", null, _toDisplayString(_unref(t)(`key.feature.list[${i - 1}].title`)), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)(`key.feature.list[${i - 1}].desc`)), 1)]);
      }), 64))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('key.qa.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('key.qa.desc')), 1), _createElementVNode("div", _hoisted_8, [_cache[1] || (_cache[1] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(qa.value, (isShow, i) => {
        return _openBlock(), _createElementBlock("li", {
          class: _normalizeClass(isShow && 'show')
        }, [_createElementVNode("div", {
          class: "q",
          onClick: $event => clickShow(isShow, i)
        }, [_createElementVNode("div", _hoisted_10, [isShow ? (_openBlock(), _createElementBlock("img", _hoisted_11)) : (_openBlock(), _createElementBlock("img", _hoisted_12))]), _createElementVNode("h4", null, _toDisplayString(_unref(t)(`key.qa.list[${i}].q`)), 1)], 8, _hoisted_9), isShow ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_unref(t)(`key.qa.list[${i}].a`)), 1)) : _createCommentVNode("", true)], 2);
      }), 256))])])]), _createElementVNode("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('key.use.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('key.use.desc')), 1), _createVNode(Download)]), _cache[2] || (_cache[2] = _createElementVNode("div", {
        class: "right"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_4
      })], -1))])])]);
    };
  }
});