import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(Header), _createVNode(_component_router_view), _createVNode(Footer)], 64);
    };
  }
});