import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/imgs/logo.png';
import _imports_1 from '@/assets/imgs/menus.png';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props) {
    const showRight = ref(false);
    const {
      t
    } = useI18n();
    const clickBody = () => {
      showRight.value = false;
      document.body.removeEventListener('click', clickBody);
    };
    const clickToggleMenu = () => {
      showRight.value = !showRight.value;
      if (showRight.value) {
        setTimeout(() => {
          document.body.addEventListener('click', clickBody);
        }, 100);
      } else {
        document.body.removeEventListener('click', clickBody);
      }
    };
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("header", null, [_createVNode(_component_router_link, {
        to: "/"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", {
          class: "left"
        }, [_createElementVNode("img", {
          src: _imports_0
        }), _createElementVNode("span", null, "Mornin")], -1)])),
        _: 1
      }), _createElementVNode("img", {
        class: "menus",
        src: _imports_1,
        alt: "",
        onClick: clickToggleMenu
      }), _createElementVNode("div", {
        class: _normalizeClass(['right', showRight.value && 'show'])
      }, [_createElementVNode("span", null, [_createVNode(_component_router_link, {
        to: "/key"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("Mornin Key")])),
        _: 1
      })]), _createElementVNode("span", null, [_createVNode(_component_router_link, {
        to: "/zone"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("Mornin Zone")])),
        _: 1
      })])], 2)]);
    };
  }
});