import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/imgs/logo_white.png';
const _hoisted_1 = {
  class: "left"
};
const _hoisted_2 = {
  class: "media"
};
const _hoisted_3 = ["href"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "mornin"
};
const _hoisted_6 = {
  class: "right"
};
import { useI18n } from 'vue-i18n';
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {
    const {
      t
    } = useI18n();
    const mediaList = [{
      name: 'medium',
      link: '#'
    }, {
      name: 'twitter',
      link: '#'
    }, {
      name: 'reddit',
      link: '#'
    }, {
      name: 'telegram',
      link: '#'
    }, {
      name: 'discord',
      link: '#'
    }, {
      name: 'youtube',
      link: '#'
    }];
    const currentYear = new Date().getFullYear();
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("footer", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("img", {
        alt: "Vue logo",
        class: "logo",
        src: _imports_0
      }, null, -1)), _createElementVNode("ul", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(mediaList, item => {
        return _createElementVNode("li", null, [_createElementVNode("a", {
          href: item.link
        }, [_createElementVNode("img", {
          src: require(`@/assets/imgs/${item.name}.png`),
          alt: ""
        }, null, 8, _hoisted_4)], 8, _hoisted_3)]);
      }), 64))]), _createElementVNode("div", _hoisted_5, "One Mornin Ltd © " + _toDisplayString(_unref(currentYear)), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("ul", null, [_createElementVNode("li", null, _toDisplayString(_unref(t)('footer.product')), 1), _createElementVNode("li", null, [_createVNode(_component_router_link, {
        to: "/key"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('footer.key')), 1)]),
        _: 1
      })]), _createElementVNode("li", null, [_createVNode(_component_router_link, {
        to: "/zone"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(t)('footer.zone')), 1)]),
        _: 1
      })])]), _createElementVNode("ul", null, [_createElementVNode("li", null, _toDisplayString(_unref(t)('footer.support')), 1), _createElementVNode("li", null, _toDisplayString(_unref(t)('footer.help')), 1), _createElementVNode("li", null, _toDisplayString(_unref(t)('footer.contact')), 1), _createElementVNode("li", null, _toDisplayString(_unref(t)('footer.kit')), 1)]), _createElementVNode("ul", null, [_createElementVNode("li", null, _toDisplayString(_unref(t)('footer.legal')), 1), _createElementVNode("li", null, _toDisplayString(_unref(t)('footer.privacy')), 1), _createElementVNode("li", null, _toDisplayString(_unref(t)('footer.terms')), 1)])])])]);
    };
  }
});