import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/imgs/product/main.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "wrap wrap-top"
};
const _hoisted_3 = {
  class: "top"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "wrap wrap-why"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "wrap wrap-plan"
};
const _hoisted_8 = {
  class: "desc"
};
const _hoisted_9 = {
  class: "per"
};
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = {
  class: "desc"
};
const _hoisted_12 = {
  class: "per"
};
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = {
  class: "desc"
};
const _hoisted_15 = {
  class: "per"
};
const _hoisted_16 = ["innerHTML"];
const _hoisted_17 = {
  class: "sales"
};
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Download from '@/components/Download.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'Product',
  setup(__props) {
    const {
      t
    } = useI18n();
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_0
      })], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('product.top.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('product.top.desc')), 1), _createVNode(Download)])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('product.feature.title')), 1), _createElementVNode("ul", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, i => {
        return _createElementVNode("li", null, [_createElementVNode("img", {
          src: require(`@/assets/imgs/product/${i - 1}.png`),
          alt: ""
        }, null, 8, _hoisted_6), _createElementVNode("h3", null, _toDisplayString(_unref(t)(`product.feature.list[${i - 1}].title`)), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)(`product.feature.list[${i - 1}].desc`)), 1)]);
      }), 64))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('product.plan.title')), 1), _createElementVNode("ul", null, [_createElementVNode("li", null, [_createElementVNode("h3", null, _toDisplayString(_unref(t)(`product.plan.essential.name`)), 1), _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)(`product.plan.essential.desc`)), 1), _createElementVNode("h2", null, _toDisplayString(_unref(t)(`product.plan.essential.price`)), 1), _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)(`product.plan.per`)), 1), _createElementVNode("button", null, _toDisplayString(_unref(t)(`product.plan.buy`)), 1), _createElementVNode("ol", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, i => {
        return _createElementVNode("li", {
          innerHTML: _unref(t)(`product.plan.essential.list[${i - 1}]`)
        }, null, 8, _hoisted_10);
      }), 64))])]), _createElementVNode("li", null, [_createElementVNode("h3", null, _toDisplayString(_unref(t)(`product.plan.professional.name`)), 1), _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)(`product.plan.professional.desc`)), 1), _createElementVNode("h2", null, _toDisplayString(_unref(t)(`product.plan.professional.price`)), 1), _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(t)(`product.plan.per`)), 1), _createElementVNode("button", null, _toDisplayString(_unref(t)(`product.plan.buy`)), 1), _createElementVNode("ol", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, i => {
        return _createElementVNode("li", {
          innerHTML: _unref(t)(`product.plan.professional.list[${i - 1}]`)
        }, null, 8, _hoisted_13);
      }), 64))])]), _createElementVNode("li", null, [_createElementVNode("h3", null, _toDisplayString(_unref(t)(`product.plan.advanced.name`)), 1), _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(`product.plan.advanced.desc`)), 1), _createElementVNode("h2", null, _toDisplayString(_unref(t)(`product.plan.advanced.price`)), 1), _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(t)(`product.plan.per`)), 1), _createElementVNode("button", null, _toDisplayString(_unref(t)(`product.plan.buy`)), 1), _createElementVNode("ol", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, i => {
        return _createElementVNode("li", {
          innerHTML: _unref(t)(`product.plan.advanced.list[${i - 1}]`)
        }, null, 8, _hoisted_16);
      }), 64))])])]), _createElementVNode("button", _hoisted_17, _toDisplayString(_unref(t)('product.plan.btn')), 1)])]);
    };
  }
});