import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/imgs/contact.webp';
const _hoisted_1 = {
  class: "contact wrap-dec"
};
const _hoisted_2 = {
  class: "wrap"
};
const _hoisted_3 = {
  class: "shadow1"
};
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import 'element-plus/es/components/message/style/css';
export default /*@__PURE__*/_defineComponent({
  __name: 'Contact',
  setup(__props) {
    const {
      t
    } = useI18n();
    const warnMap = reactive({
      fullName: false,
      businessType: false,
      email: false,
      message: false
    });
    const form = reactive({
      fullName: '',
      businessType: 'other',
      email: '',
      message: ''
    });
    const _options = ['food', 'retail', 'services', 'education', 'media', 'tech', 'other'];
    const options = _options.map(item => ({
      label: t(`contact.businessTypeMap.${item}`),
      value: item
    }));
    const clickSubmit = async () => {
      warnMap.fullName = form.fullName.trim() === '';
      warnMap.email = form.email.trim() === '';
      warnMap.message = form.message.trim() === '';
      if (warnMap.fullName || warnMap.email || warnMap.message) return;
      if (form.email.indexOf('@') === -1) {
        warnMap.email = true;
        return;
      }
      try {
        await axios.post(`https://safe-api.mixin.zone/customer_services`, {
          full_name: form.fullName,
          email: form.email,
          business: form.businessType,
          message: 'mornin.one::::' + form.message
        });
      } catch (e) {
        ElMessage.error(t('toast.submit.fail'));
      }
      ElMessage.success(t('toast.submit.success'));
      form.fullName = '';
      form.email = '';
      form.message = '';
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString(_unref(t)(`contact.title`)), 1), _createElementVNode("div", _hoisted_3, [_cache[4] || (_cache[4] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)), _createElementVNode("div", null, [_createElementVNode("div", {
        class: _normalizeClass([warnMap.fullName && 'error'])
      }, [_createElementVNode("p", null, _toDisplayString(_unref(t)(`contact.fullName`)), 1), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.fullName = $event)
      }, null, 512), [[_vModelText, form.fullName]])], 2), _createElementVNode("div", {
        class: _normalizeClass([warnMap.businessType && 'error'])
      }, [_createElementVNode("p", null, _toDisplayString(_unref(t)(`contact.businessType`)), 1), _createVNode(_component_el_select, {
        modelValue: form.businessType,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.businessType = $event),
        placeholder: "Select",
        size: "large"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: item.value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])], 2), _createElementVNode("div", {
        class: _normalizeClass([warnMap.email && 'error'])
      }, [_createElementVNode("p", null, _toDisplayString(_unref(t)(`contact.email`)), 1), _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => form.email = $event)
      }, null, 512), [[_vModelText, form.email]])], 2), _createElementVNode("div", {
        class: _normalizeClass(['mb-[32px]', warnMap.message && 'error'])
      }, [_createElementVNode("p", null, _toDisplayString(_unref(t)(`contact.message`)), 1), _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.message = $event)
      }, null, 512), [[_vModelText, form.message]])], 2), _createElementVNode("button", {
        onClick: clickSubmit
      }, _toDisplayString(_unref(t)(`contact.submit`)), 1)])])])]);
    };
  }
});