import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/imgs/support/main.png';
import _imports_1 from '@/assets/imgs/support/key.png';
import _imports_2 from '@/assets/imgs/support/lock.png';
import _imports_3 from '@/assets/imgs/support/view.png';
import _imports_4 from '@/assets/imgs/support/data.png';
import _imports_5 from '@/assets/imgs/support/use.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "wrap wrap-top"
};
const _hoisted_3 = {
  class: "top"
};
const _hoisted_4 = {
  class: "right"
};
const _hoisted_5 = {
  class: "wrap wrap-why"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "wrap wrap-manager"
};
const _hoisted_8 = {
  class: "manager"
};
const _hoisted_9 = {
  class: "right"
};
const _hoisted_10 = {
  class: "wrap wrap-key"
};
const _hoisted_11 = {
  class: "zone"
};
const _hoisted_12 = {
  class: "left"
};
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Download from '@/components/Download.vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'Support',
  setup(__props) {
    const {
      t
    } = useI18n();
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_0
      })], -1)), _createElementVNode("div", _hoisted_4, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('support.top.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('support.top.desc')), 1), _createVNode(Download)])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('support.why.title')), 1), _createElementVNode("ul", null, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, i => {
        return _createElementVNode("li", null, [_createElementVNode("img", {
          src: require(`@/assets/imgs/support/${i - 1}.png`),
          alt: ""
        }, null, 8, _hoisted_6), _createElementVNode("h3", null, _toDisplayString(_unref(t)(`support.why.list[${i - 1}].title`)), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)(`support.why.list[${i - 1}].desc`)), 1)]);
      }), 64))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_cache[4] || (_cache[4] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("h1", null, _toDisplayString(_unref(t)('support.manager.title')), 1), _createElementVNode("div", _hoisted_9, [_createElementVNode("h3", null, [_cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_2,
        alt: ""
      }, null, -1)), _createTextVNode(" " + _toDisplayString(_unref(t)('support.manager.info[0]')), 1)]), _createElementVNode("h3", null, [_cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_3,
        alt: ""
      }, null, -1)), _createTextVNode(" " + _toDisplayString(_unref(t)('support.manager.info[1]')), 1)]), _createElementVNode("h3", null, [_cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)), _createTextVNode(" " + _toDisplayString(_unref(t)('support.manager.info[2]')), 1)])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("h1", null, _toDisplayString(_unref(t)('support.use.title')), 1), _createElementVNode("p", null, _toDisplayString(_unref(t)('support.use.desc')), 1), _createVNode(Download)]), _cache[5] || (_cache[5] = _createElementVNode("div", {
        class: "right"
      }, [_createElementVNode("img", {
        alt: "",
        src: _imports_5
      })], -1))])])]);
    };
  }
});